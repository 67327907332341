import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
import { postMethod } from "../../utils/api";
import { API_URL } from "../../constants/url.constant";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from "../../constants/url.constant";
import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import OTPModal from "../Modal/OtpModal";
import Modal from "../Modal/Modal";
import useModal from "../../hooks/useModal";
import { useState } from "react";
import { backgroundColor } from "styled-system";


// ----------------------------------------------------------------------

export default function OtpLogin() {
  const { isModalOpen, showModal, hideModal } = useModal();
  const [mobileNumer, setMobileNumber] = useState("");
  const navigate = useNavigate();
  const sendOTP = async () => {
    try {
      // setLoading(true);
      const response = await postMethod(API_URL.SEND_OTP, { mobile: mobileNumer }, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        showModal();
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };



  function handleOnChange(value) {
    setMobileNumber(value)
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <PhoneInput
          country={'in'}
          onlyCountries={['in']}
          onChange={handleOnChange}
          inputStyle={{
            width: "100%"
          }}
          enableSearch={true}
          countryCodeEditable={false}
        />
        <Button onClick={() => sendOTP()} fullWidth size="large" color="inherit" variant="outlined">
          {/* <Icon icon={googleFill} color="#DF3E30" height={24} /> */} Send OTP
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          OR
        </Typography>
      </Divider>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        content={<OTPModal mobileNumer={mobileNumer} />}
      />
    </>
  );
}
