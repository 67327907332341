import React from "react";
import { Counter } from "../../components/counter/counter";
//import  CURRENCY  from "../../constants/content.constant/CURRENCY";
import {
  ItemBox,
  Image,
  Information,
  Name,
  Price,
  Weight,
  Total,
  RemoveButton,
} from "./cart-item.style";
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from "@mui/material";

export const CartItem = ({
  data,
  onDecrement,
  onIncrement,
  onRemove,
}) => {

  const { title, images, price, salePrice, unit, quantity } = data;
  const displayPrice = salePrice ? salePrice : price;
  return (
    <>
      <ItemBox >
        <Counter
          value={quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          variant="vertical"
        />
        <Image src={images?.url} />
        <Information>
          <Name className="text-nowrap" >{title}</Name>
          <Price>
            {/* {CURRENCY} */}Rs.
            {displayPrice}
          </Price>
          <Weight>
            {quantity} X {unit}
          </Weight>
        </Information>
        <div className="text-nowrap" style={{position:"absolute",right:5}} >
        <Total>
          {/* {CURRENCY} */}Rs.
          {(quantity * displayPrice).toFixed(2)}
        </Total>
        <RemoveButton onClick={onRemove}>
          < CloseIcon />
        </RemoveButton>
        </div>
      </ItemBox>
      <Divider />
    </>
  );
};
