import React, { useEffect, useState } from 'react';
import { API_URL } from '../../constants/url.constant';
import { getMethod } from '../../utils/api';
import { API_STATUS_CODE } from '../../constants/content.constant';
import { getArticleName } from '../../utils/helper';

const FetchOrderComponent = ({ steps, triggerNextStep }) => {
  const orderId = steps.collectInfo.value; // Get the user's input
  const [orderInfo, setOrderInfo] = useState(null);
  const [articleInfo, setArticleInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [result, setResult] = useState(null); // Track result status

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        console.log("Fetching order for ID:", orderId);
        const response = await getMethod(API_URL.GET_ORDER_BY_NUMBER + "/" + orderId, false, false);
        const response1 = await getMethod(API_URL.GET_ARTICLE , false, false);
        console.log("API Response:", response);

        if (response?.status === API_STATUS_CODE.SUCCESS && response.data.data) {
          setOrderInfo(response.data.data);
          setArticleInfo(response1.data.data);
          console.log(response)
          setResult("yes"); // Indicate that the order ID is valid
          triggerNextStep({ value: "yes" }); // Proceed to next step
        } else {
          console.error("Failed to fetch order details:", response);
          setResult("no"); // Indicate that the order ID is invalid
          triggerNextStep({ value: "no" }); // Re-prompt user for order ID
        }
      } catch (e) {
        console.error("Error fetching order details:", e);
        setResult("no"); // Treat fetch error as invalid order ID
        triggerNextStep({ value: "no" }); // Re-prompt user for order ID
      } finally {
        setLoading(false); // Set loading to false after fetch attempt
      }
    };

    fetchOrder();
  }, [orderId, triggerNextStep]);

  if (loading) {
    return <div>Loading your order details...</div>; // Display loading message
  }
// console.log(orderInfo)

const orderItemName = (data) => {
  const items = data?.items; // Ensure 'items' is correctly referenced
  if (!items) {
    console.error("No items found in data");
    return;
  }
  
  const itemIds = items.map((i) => i.product_id); // Extract product IDs

  const articleNames = itemIds.map((id) => {
    return getArticleName(articleInfo, id); // Map IDs to article names
  });

  console.log(articleNames); // Log the result
  return articleNames.join(", "); // Combine and return the article names as a comma-separated string
};

  return (
    <div>
      {result === "yes" ? (
        <div>
          <h4>Order Details:</h4>
          <p>Order Item: {orderItemName(orderInfo)}</p>
          {/* <p>Items: {orderInfo.items.join(", ")}</p> */}
          <p>Status: Delivered</p>
          {/* Add more order details as needed */}
        </div>
      ) : (
        <div>Sorry, we couldn't find an order with that ID. Please try again.</div>
      )}
    </div>
  );
};

export default FetchOrderComponent;
