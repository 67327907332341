import { useEffect, useState } from "react";
// library
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  Card,
  Button,
} from "@mui/material";
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
import { getMethod, postMethod, putMethod } from "../utils/api.jsx";
import { API_URL } from "../constants/url.constant";
import { API_STATUS_CODE, MESSAGE } from "../constants/content.constant";
import { useSelector } from "react-redux";
import { getArticleName } from "../utils/helper";
import Modal from "../components/Modal/Modal";
import CommentAndRating from "../components/Review/CommentAndRating";
import toastr from "toastr";
import {  formatTime } from "../utils/formatTime.js";

export default function History() {
  const data = useSelector((state) => state.machineSlice.machine);
  const idno = data ? data.idno : "";
  const [review, setReview] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [orderData, setOrderData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    try {
      fetchOrders();
      fetchArticle();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const callbackSetRating = (reviewData, type) => {
    //revieving state from child component and updating in this component
    setReview({
      ...review,
      [type]: reviewData,
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await getMethod(
        API_URL.GET_ORDERS_MACHINE + "/" + idno,
        false,
        false
      );
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setOrderData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchArticle = async () => {
    try {
      const response = await getMethod(API_URL.GET_ARTICLE, false, false);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setProductData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeModal = (order_id, defaultReview) => {
    setReview({
      ...review,
      ["comment"]: defaultReview?.comment,
      ["star"]: defaultReview?.star,
      ["id"]: defaultReview?._id,
    });
    setShowModal(true);
    setSelectedOrder(order_id);
  };
  const handleSubmitRating = async () => {
    // if (!review) {
    //     setReview({ comment: null, star: 0 })
    // }
    review.order_id = selectedOrder?._id;
    const data = review;

    try {
      var response = "";
      if (review?.id) {
        response = await putMethod(
          API_URL.UPDATE_REVIEW + "/" + review?.id,
          data,
          false,
          false
        );
      } else {
        response = await postMethod(API_URL.POST_REVIEW, data, false, false);
      }
      //   console.log("handleSubmit", response);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        fetchOrders(); //to refresh and fetch updated reviews
        toastr.success(MESSAGE.REVIEW_POST_SUCCESS);
      }
    } catch (error) {
      console.log(error);
    }
    handleCloseModal();
  };
  return (
    <>
      <Outlet />
      <Page title="Clover Carte | History">
        <Container maxWidth="xl" sx={{ flex: "1 0 auto", height: "80vh" }}>
          <Box>
            <Grid container spacing={3} sx={{}}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ color: "#07ad56" }}
                >
                  Today Order
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Card className="p-3" item xs={12} sm={12} md={6}>
              <div class="table-responsive" style={{height:"60vh"}}>
                <table className="table table-striped table-bordered table-hover">
                  <thead className="text-nowrap">
                    <tr>
                      <th>S.No.</th>
                      <th>Time</th>
                      <th>Item</th>
                      <th>ORD. ID</th>
                      <th>Amount ₹</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData?.map((order, index) => (
                      <tr className="text-nowrap">
                        <td>{index + 1}</td>
                        <td className="text-uppercase" >
                          {formatTime(order.createdAt)}
                        </td>
                        <td>
                          {getArticleName(
                            productData,
                            order.items[0]?.product_id
                          )}
                        </td>
                        <td>{order.orderNumber}</td>
                        <td>{order.totalAmount * 100}</td>
                        <td>
                          <Button
                            onClick={() =>
                              handleChangeModal(order, order?.review)
                            }
                            size="small"
                          >
                            Rate & Review
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Box>
        </Container>
        <Modal
          isOpen={showModal}
          closeModal={handleCloseModal}
          title={"Review Order"}
          dialogProps={{}}
          titleStyle={{ textAlign: "left", marginTop: 2 }}
          actionStyle={{ justifyContent: "right", marginBottom: 2 }}
          contentStyle={{ width: "100%", height: "400px" }}
          dialogStyle={{}}
          content={
            <>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Hope you had a good experience with us…anything we could
                  improve or that you liked?
                </Typography>
                {/* {Review({ questions, current, total })} */}
                <CommentAndRating
                  onClick={callbackSetRating}
                  default={{ comment: review?.comment, star: review?.star }}
                />
              </Box>
            </>
          }
          action={
            <>
              {/* <Grid container direction='row'>
                            <Grid item>{(currentQuestion === 0) ? "" : <Button onClick={() => setCurrentQuestion((currentQuestion === 0) ? currentQuestion : currentQuestion - 1)}>Previous</Button>} </Grid>
                            <Grid item> {(currentQuestion + 1 === questions.length) ? "" : <Button onClick={() => setCurrentQuestion(((currentQuestion + 1) === questions.length) ? currentQuestion : currentQuestion + 1)}>Next</Button>}</Grid>
                        </Grid> */}
              <Button
                size="large"
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmitRating}
              >
                Submit
              </Button>
            </>
          }
        />
      </Page>
    </>
  );
}
