// css
import "./styles/global.css";
import "simplebar/src/simplebar.css";
import "toastr/build/toastr.min.css";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
// Context 
import { CartProvider } from './contexts/cart/use-cart';
import { LanguageProvider } from "./contexts/language/language.provider";
import { messages } from "./site-settings/site-translation/messages";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

//google auth
import { GoogleOAuthProvider } from "@react-oauth/google"


//
import App from "./App";

// ----------------------------------------------------

// development
//  clientId={`1083151451213-608555848oegpfn9nevg36ce7jt5ptmp.apps.googleusercontent.com`}

ReactDOM.render(
  <HelmetProvider>
    <LanguageProvider messages={messages}>
      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <CartProvider>
            <GoogleOAuthProvider
              // clientId={`516524833450-1p070nrajmsor53h5t6h97b302h2m8u9.apps.googleusercontent.com`}>
              clientId={`1083151451213-608555848oegpfn9nevg36ce7jt5ptmp.apps.googleusercontent.com`}>
              <App />
            </GoogleOAuthProvider>
          </CartProvider>
        </PersistGate>
      </Provider>
    </LanguageProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

// serviceWorkerRegistration.register()