import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  machine: {},
};

const machineSlice = createSlice({
  name: "machine",
  initialState: INIT_STATE,
  reducers: {
    actionMachine: (state, action) => {
      state.machine = action.payload;
      return state;
    },
  },
});

export const { actionMachine } = machineSlice.actions;

export default machineSlice.reducer;
