import React from 'react';
import styled from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Page from '../components/Page';
import { Outlet } from 'react-router-dom';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;



export default function AboutUs() {
  return (
    <>
      <Outlet />
      <Page title="Clover Carte | About Us">
        {/* <Modal> */}
          <HelpPageWrapper>
            <HelpPageContainer>
              <Link to="/">
                <a className="home-btn">
                  <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
                </a>
              </Link>
              <Heading>Welcome to Clover Carte </Heading>

              <b><h6> Clover Carte™ is a registered trademark for proposed brand and company for food and related products. Beverages and food are an integral part of our everyday life. In many nations, day meals are mostly taken at places away from home by working people.</h6></b>
              <br></br>
              <p> App based food delivery system has added a new dimension in the food business sector. We at Clover Carte are working in tandem with Robotized beverages and food making and dispensing system.</p>
              <br></br>
              <p>
                Focus is on catering freshly prepared healthy beverages /
                food with the help of fully automated  AI-ML based vending  machines
                We are a technology-based company, this machine is entirely in house designed.
                Our expertise caters to mechanical, Electronics,IoT & Cloud Computing.
                We have experience in ingredients & recipe making.</p>

            </HelpPageContainer>

          </HelpPageWrapper>
        {/* </Modal> */}
      </Page>
    </>
  );
}
