import { getLocalData } from "../../utils/helper";
import CustomOptions from "./CustomOption";
import VideoComponent from "./VideoCompnent";
import FetchOrderComponent from "./FetchOrderComponent";
import CallingButton from "./CallingButton";
import FetchWalletComponent from "./FetchWallet";

const localUserData = getLocalData("user");
const userData = localUserData ? JSON.parse(localUserData) : {};
const anchor = <a href="tel:7987582705" >call Now</a>

const isValidNumber = (input) => {
  const number = Number(input);
  return !isNaN(number) && number > 0; // Check if input is a valid positive number
};

export const ChatBotSteps = [
  {
    id: "1",
    message: `Hello ${userData.firstName?.charAt(0).toUpperCase() + userData.firstName?.slice(1)}! 😊 How can I assist you today?`,
    trigger: "2",
  },
  {
    id: "2",
    options: [
      { value: 1, label: "Order", trigger: "orderCondition" },
      { value: 2, label: "Wallet", trigger: "fetchWallet" },
    ],
  },
  {
    id:'orderCondition',
   component:(<CustomOptions options={[
       { value: 1, label: "How to order", trigger: "how2order" },
       { value: 2, label: "How to customize order", trigger: "how2customize" },
       { value: 3, label: "Order Info", trigger: "orderInfo" },
       { value: 4, label: "Other", trigger: "callingopt" },
      ]} />),
      
  },
  {
    id: "callingopt",
    message: `For more support, you can reach us by clicking the "Call Now" button below.`,
    trigger: "callingbtn",
  },
  {
    id:'callingbtn',
   component:(<CallingButton options={[
       { value: 1, label: "Call Now", trigger: "endMessage" }
      ]} />),
      
  },
  {
    id: 'how2order',
    component: <VideoComponent />,
    trigger: 'endMessage',
  },
  {
    id: 'how2customize',
    component: <VideoComponent />,
    trigger: 'endMessage',
  },
  {
    id: "orderInfo",
    message: "Please provide your order ID to find your order information.",
    trigger: "collectInfo",
  },
  {
    id: "collectInfo",
    user: true,
    validator: (value) => {
      if (!isValidNumber(value)) {
        return 'Please enter a valid order ID number.';
      }
      return true;
    },
    trigger: "fetchOrder",
  },
  {
    id: "fetchOrder",
    component: <FetchOrderComponent />,
    asMessage: true,
    trigger: 'morehelp',
  },
  {
    id: "fetchWallet",
    component: <FetchWalletComponent />,
    asMessage: true,
    trigger: 'morehelp',
  },
  {
    id: "morehelp",
    message: "Is there anything else you need help with?",
    asMessage: true,
    trigger: 'moreoption',
  },
  {
    id: "moreoption",
    component:(<CustomOptions options={[
      { value: 1, label: "Yes", trigger: "2" },
      { value: 2, label: "No", trigger: "endMessage" },
     ]} />),

  },
  {
    id: "endMessage",
    message: "Thank you for connect with us! If you need any more help, feel free to reach out. Take care!",
    asMessage: true,
    end: true,
  }
];
