import React from "react";
import {
  CheckoutButtonWrapper,
  CheckoutButton,
  Title,
  PriceBox,
} from "./cart.style";

import { FormattedMessage } from "react-intl";
import { checkUser } from "../../utils/checkUser";
import { useNavigate } from 'react-router-dom';
import { useCart } from "../../contexts/cart/use-cart";
import { PaymentMethod } from "../../components/paymentAction/PaymentAction";

const CheckoutBtnBottom = () => {
  const navigate = useNavigate();
  const { cartItemsCount, calculatePrice, items } = useCart();
  const payDetails = {
    total_amount: calculatePrice(),
    title: '',
    items: items
  };

  const user = checkUser();

  const checkOutBtnClickOld = () => {
    if (!user) {
      PaymentMethod(payDetails)
    } else {
      navigate("/payment-options", { replace: true });
    }
  }

  const checkOutBtnClick = () => {
    navigate("/confirm-order", { replace: true });
  }

  return (
    <CheckoutButtonWrapper>
      {cartItemsCount() !== 0 ? (
        <CheckoutButton onClick={checkOutBtnClick}>
          <>
            <Title>
              <FormattedMessage
                id="navlinkCheckout"
                defaultMessage="Checkout"
              />
            </Title>
            <PriceBox>
              Rs.
              {calculatePrice()}
            </PriceBox>
          </>
        </CheckoutButton>
      ) : (
        <CheckoutButton>
          <>
            <Title>
              <FormattedMessage
                id="navlinkCheckout"
                defaultMessage="Checkout"
              />
            </Title>
            <PriceBox>
              Rs.
              {calculatePrice()}
            </PriceBox>
          </>
        </CheckoutButton>
      )}
    </CheckoutButtonWrapper>
  );
};

export default CheckoutBtnBottom;
