import { useEffect } from "react";
// library
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider
} from "@mui/material";
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
import QR_HAND_IMAGE from "../assets/images/qr_hand.jpg"


export default function WelcomeHome() {

  useEffect(() => {
  }, []); 

  useEffect(() => {
    try {

    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <Outlet />
      <Page title="Clover Carte | Home">
        <Container maxWidth="xl" sx={{ flex: '1 0 auto', height: '80vh', }}>
          <Box>
            <Grid container spacing={3} sx={{}}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography gutterBottom variant="h1" component="div" sx={{ color: "#07ad56" }}>
                  Welcome, to Clover Carte
                </Typography>
                <Typography gutterBottom variant="h6" component="div" sx={{}}>
                  Scan the QR Code, Browse the Menu and Enjoy Your Meal…
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}  >
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="right"
                  sx={{ p: 2 }}
                >
                    <img src={QR_HAND_IMAGE} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
}