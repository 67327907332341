import React, { useState } from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import OrderReceivedWrapper, {
  OrderReceivedContainer,
  // OrderInfo,
  // OrderDetails,
  // TotalAmount,
  // BlockTitle,
  // Text,
  // InfoBlockWrapper,
  // InfoBlock,
  // ListItem,
  // ListTitle,
  // ListDes,
} from "./order-received.style";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../../contexts/cart/use-cart";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Page from "../../../components/Page";
import { Outlet } from "react-router-dom";
import { WEBSOCKET_GATEWAY } from "../../../constants/url.constant";
import { decodeMachineMsg } from "../../../utils/decodeOrderstatus";
import OrderStatus from "../../../components/Order/OrderStatus";
import PreviousOrder from "../PreviousOrder";
import { Chip, Divider } from "@mui/material";
import { ROUTES_URL } from "../../../constants/url.constant";

//vercel api
//https://v2-clovercarte-api.vercel.app/

//heroku api 
//https://clover-carte-api.herokuapp.com

// const client = new W3CWebSocket(WEBSOCKET_GATEWAY);
// client.onopen = (connection) => {
//   // connection opened
//   client.send('Hello from client ');  // send a message
//   console.log("connected", connection)
// };
const MyOrders = (props) => {
  const { clearCart } = useCart();
  const [orderStatus, setOrderStatus] = useState([]);
  // console.log("items", items);
  // console.log("calculatePrice", calculatePrice());
  // console.log("calculateSubTotalPrice", calculateSubTotalPrice());

  function updateOrderStatus(data) {
    let arr = orderStatus;
    // const doesExist = (element) => element == data.pro_code;
    let index = (arr.findIndex(x => x.pro_code === data.pro_code));
    if (index >= 0) {
      arr[index].message = data.message;
      setOrderStatus(arr)
    }
    if (index === -1) {
      setOrderStatus(previousOrder => [...previousOrder, data])
    }
  }
  React.useEffect(() => {
    return () => {
      clearCart();
      //eslint-disable-next-line
    };
  }, []);

  // client.onmessage = (message) => {
  //   updateOrderStatus(decodeMachineMsg(message.data))
  //  // let tempData = decodeMachineMsg(message.data)

  //   //updateOrderStatus(JSON.parse(message.data))

  //   // setOrderStatus( previousOrder => [...previousOrder, JSON.parse(message.data)] )
  //   // console.log("Live status", message.data)
  // }

  return (
    <>
      <Outlet />
      <Page title="Clover Carte | My Order">
        <OrderReceivedWrapper>
          <OrderReceivedContainer>
            <Link to={ROUTES_URL.HOME}>
              <p className="home-btn">
                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
              </p>
            </Link>
            {/* <Divider textAlign="left"><Chip label="Active Order" /></Divider>
            <OrderStatus /> */}
            <Divider textAlign="left"><Chip label="Previous Order" /></Divider>
            <PreviousOrder />

          </OrderReceivedContainer>
        </OrderReceivedWrapper>
      </Page>
    </>
  );
};

export default MyOrders;
