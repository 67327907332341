import axios from "axios";
// constants
import { API_URL } from "../../constants/url.constant";
import { postMethod } from "../../utils/api";
import { getLocalData, getSessionData, setLocalData } from "../../utils/helper";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const PaymentMethod = async ({ total_amount, title, items }) => {
  let user = JSON.parse(getLocalData("user"))
  const sp_id = getLocalData("HOME")
  const name = user ? user.firstName : ""
  const email = user ? user.email : ""
  const contact = user ? user.contact : (getLocalData("guestMobile"))
  const customer_id = user ? user.customer_id : ""
  const guestMobile = (getLocalData("guestMobile"))
  let guestUser = {}

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  let productsQ = items && items?.map(i => {
    return { id: i?._id, title: i?.title, quantity: i?.quantity }
  })

  axios.post(API_URL.ORDER, { totalAmt: '1' }).then((info) => {
    var options = {
      key: '',// process.env.RAZORPAY_ID,
      key_secret: '',// process.env.RAZORPAY_SECRET, // Enter the Key ID generated from the Dashboard

      name: "Clover Carte",
      description: title,
      image: "https://clovercarte.in/logo2.png",
      order_id: info.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // callback_url: '/order-received', // API_URL.ORDER_COMPLETE,
      handler: async function (response) {
        console.log("rzp-response", response);

        if (!user && !user?.contact) {
          console.log("guestMobile", guestMobile)
          guestUser.contact = guestMobile
        }

        const finalRes = await postMethod(API_URL.ORDER_COMPLETE, { razorpay_payment_id: response?.razorpay_payment_id, items: items, user: user ? user : guestUser, sp_id }, false, false)
        console.log("final response", finalRes)
        // console.log("final response", finalRes?.data?.mobile)


        // console.log("finalRes-response", id);

        if (finalRes?.status === 200) {
          const mobile = finalRes?.data?.data?.mobile
          const id = finalRes?.data?.data?.order_id_client
          if (mobile) setLocalData("guestMobile", mobile);
          window.location.assign('/order/order-id/' + id)
        }
      },
      redirect: false,
      // remember_customer: true,
      customer_id: customer_id,
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },

      // save: 1,
      notes: {
        address: "Purchase made using Clover Carte",
        // msg:items?.map(i=>i?.title)?.toString(),
        msg: productsQ ? JSON.stringify(productsQ) : ''
      },
      theme: {
        color: "#000000",
      },
    };
    // console.log("info", info);
    const paymentObject = new (window).Razorpay(options);
    paymentObject.open();
  });
};
