import React from 'react'
import Chatbottest from './Chatbottest'

function ChatBotAssembly() {
  return (
    <div>
      <Chatbottest/>
    </div>
  )
}

export default ChatBotAssembly
