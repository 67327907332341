import { combineReducers } from "redux";
import User from "./slices/user.slice";
import machineSlice from "./slices/machine.slice";

const rootReducer = combineReducers({
  User,
  machineSlice,
});

export default rootReducer;
