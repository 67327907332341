import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./pages/Auth/Login";
import DashboardApp from "./pages/DashboardApp";
import NotFound from "./pages/Page404/Page404.page";
import { Outlet } from "react-router-dom";
import OrderReceived from "./features/order/order/OrderReceived";
import MyOrders from "./features/order/order/MyOrders";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundCancellation from "./pages/RefundCancellation";
import ShipingPolicy from "./pages/ShipingPolicy";
import TermsCondition from "./pages/TermsCondition";
import FullOrderStatus from "./features/order/order/FullOrderStatus";
import WelcomeHome from "./pages/WelcomeHome";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import MyWallet from "./pages/MyWallet";
import PaymentOptions from "./pages/PaymentOptions";
import ResetPassword from "./pages/Auth/ResetPassword";
import ConfirmOrderPage from "./pages/ConfirmOrderPage";
import History from "./pages/History";
import CustomOrder from "./pages/CustomOrder";

// import { ROUTES_URL } from "./constants/url.constant";

export default function NewRoutes() {
  return useRoutes([
    {
      path: "",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home" replace /> },
        {
          path: "",
          element: <Outlet />,
          children: [
            { path: "", element: <WelcomeHome /> },
            { path: "sp/:id", element: <DashboardApp /> },
            { path: "/my-orders", element: <MyOrders /> },
            { path: "/order/order-id/:id", element: <FullOrderStatus /> },
            { path: "/order-received/order-id/:id", element: <OrderReceived /> },
            { path: "/wallet", element: <MyWallet /> },
            { path: "/payment-options", element: <PaymentOptions /> },
            { path: "/confirm-order", element: <ConfirmOrderPage /> },
            { path: "/order-history", element: <History /> },
          ],
        }
      ],
    },
    {
      path: "/custom-order",
      element: <CustomOrder />,

    },
    {
      path: "/about-us",
      element: <AboutUs />,

    },
    {
      path: "/contact-us",
      element: <ContactUs />,

    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,

    },
    {
      path: "/refund-policy",
      element: <RefundCancellation />,

    },
    {
      path: "/shiping-policy",
      element: <ShipingPolicy />,

    },
    {
      path: "/terms",
      element: <TermsCondition />,

    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/home" /> },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        { path: "/reset-password/:token/:userId", element: <ResetPassword /> },
      ],
    },


    { path: "*", element: <Navigate to="/404" /> },
  ]);
}
