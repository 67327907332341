import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify-icon/react';
import { Typography } from '@mui/material';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

function WarningPopup(props) {
  const { statusOK, reason,pos6,pos13,pos0,pos11 } = props.machineCondition || {};
  const [isShow, setIsShow] = useState(!statusOK);

  const closeFn = () => {
    setIsShow(false);
  };

  useEffect(() => {
    setIsShow(!statusOK);
  }, [statusOK]);
  return (
    <>
      {isShow && (
        <div style={{
          height: "100vh",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 1000000,
          position: "fixed",
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: "center",
          justifyContent: "center"
        }}>
          {(pos6==='0'||pos11==='b')&&
          <div className="warn_continer card pt-5">
            <div className="modal-header" style={{ position: 'absolute', top: 15, right: 15 }}>
              <button onClick={closeFn} type="button" className="btn-close" aria-label="Close"></button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Icon icon="mdi:wifi-strength-0-warning" width='80' style={{ color: "#00AB55" }} />
              <Typography component="div" variant="h5" className='pt-4 p-4 text-center'>
                {reason}
              </Typography>
            </div>
          </div>}
          {(pos13==='W'||pos13==='w')&&
          <>
          {pos13==='W'?
          <div className="clean_continer card pt-5">
            <div className="modal-header" style={{ position: 'absolute', top: 15, right: 15 }}>
              <button onClick={closeFn} type="button" className="btn-close" aria-label="Close"></button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CountdownCircleTimer
    isPlaying
    duration={60}
    colors={['red', 'd1e7dd', 'green', 'black','green']}
    // colorsTime={[50, 40, 30, 20,0]}
    size={70}
    strokeWidth={5}
    onComplete={()=>closeFn()}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
              <Typography component="div" variant="span" className='pt-4 p-1 text-center'>

              The machine is currently being cleaned and it will take approximately 1 minute. Do you want to continue?
              </Typography>
              <div className='d-flex justify-content-around p-3' >
                <button onClick={closeFn} className='btn btn-success '>Proceed</button>
              </div>
            </div>
          </div>:
          <div className="warn_continer card pt-5">
            <div className="modal-header" style={{ position: 'absolute', top: 15, right: 15 }}>
              <button onClick={closeFn} type="button" className="btn-close" aria-label="Close"></button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CountdownCircleTimer
    isPlaying
    duration={5}
    colors={['red', 'd1e7dd', 'green', 'black','green']}
    // colorsTime={[50, 40, 30, 20,0]}
    size={70}
    strokeWidth={5}
    onComplete={()=>closeFn()}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
              <Typography component="div" variant="h4" className='pt-4 p-1 text-center'>
              {reason}
              </Typography>
            </div>
          </div>}
          </>
          }
        </div>
      )}
    </>
  );
}

export default WarningPopup;
