import React, { useState, useEffect } from "react";
import {
    Box,
    Card,
    CardContent,
    Typography,
    Divider,
    Grid,
    CardActions,
    CardMedia,
    Button,
    Stack,
    Rating
} from "@mui/material";
import { API_URL } from "../../constants/url.constant";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { getMethod } from "../../utils/api";
import { OrderInfo, BlockTitle } from "./order/order-received.style";
import { fDateTimeSuffix } from "../../utils/formatTime";
import Review from "../../components/Review/Review";
import Modal from "../../components/Modal/Modal";
import { RATING_QUESTIONS } from "../../constants/content.constant";
import CommentAndRating from "../../components/Review/CommentAndRating";
import toastr from "toastr";
import { MESSAGE } from "../../constants/content.constant";
import { postMethod, putMethod } from "../../utils/api";
import HelpAndReport from "../help/HelpAndReport";
import { getLocalData } from "../../utils/helper";

const PreviousOrder = (props) => {
    const localData = getLocalData("user")
    const User = localData? JSON.parse(localData):[]
    const [orders, setOrder] = useState(null) 
    const [review, setReview] = useState(null)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [rating, setRating] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [articleData,setArticleData] = useState([])
    const [valid,setValid] = useState(false);
    const questions = RATING_QUESTIONS,

        current = currentQuestion,
        total = RATING_QUESTIONS.length;

    const fetchPreviousOrder = async () => {
        try {
            const response = await getMethod(API_URL.GET_PREVIOUS_ORDER+"/"+User?.user_id, false, true);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setOrder(response?.data?.data)
                // console.log(response)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const fetchArticle = async () => {
        try {
          const response = await getMethod(API_URL.GET_ARTICLE, false, false);
          if (response?.status === API_STATUS_CODE.SUCCESS) {
            setArticleData(response?.data?.data);
          }
        } catch (error) {
          console.log(error);
        }
      };
    const handleCloseModal = () => { setShowModal(false) }
    const handleCloseHelpModal = () => { setShowHelpModal(false) }

    const callbackSetRating = (reviewData, type) => {
        //revieving state from child component and updating in this component
        setReview({
            ...review,
            [type]: reviewData,
        });
    }

    const handleChangeHelpModal = (order_id) => {
        setSelectedOrder(order_id);
        setShowHelpModal(true);
    }

    const handleChangeModal = (order_id, defaultReview) => {
        setReview({
            ...review,
            ["comment"]: defaultReview?.comment,
            ["star"]: defaultReview?.star,
            ["id"]: defaultReview?._id,
        });
        setShowModal(true);
        setSelectedOrder(order_id);
    }
    const handleSubmitRating = async () => {
        // if (!review) {
        //     setReview({ comment: null, star: 0 })
        // }
        review.order_id = selectedOrder?._id;
        const data = review;
         console.log("handleSubmit", data);
        if(data.star){
        try {
            var response = "";
            if (review?.id) {
                response = await putMethod(
                    API_URL.UPDATE_REVIEW + "/" + review?.id,
                    data,
                    false,
                    false
                );
            } else {
                
                response = await postMethod(
                    API_URL.POST_REVIEW,
                    data,
                    false,
                    false
                );
            }
            //   console.log("handleSubmit", response);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setValid(false) //to refresh and fetch updated reviews
                handleCloseModal()
                fetchPreviousOrder()
                toastr.success(MESSAGE.REVIEW_POST_SUCCESS);
            }

        } catch (error) {
            console.log(error);
        }}
        else{
            setValid(true)
        }
       
    }

    const getProductImage = (value)=>{
        const data = articleData?articleData:[]
        const article = data.find(item => item._id === value)
        return  article ? article.images?.url : null;
        // console.log(value)
    }
    useEffect(() => {
        fetchPreviousOrder();
        fetchArticle()
    }, []);
    // console.log(orders)
    return (
        <>
            {
                (orders?.length > 0) ? <Box>
                    {
                        orders?.map((order, index) => {
                            return <Card key={index} spacing={2} sx={{ mt: 3, border: 'ActiveBorder' }}>
                                <CardActions sx={{ backgroundColor: "#f1f2f2", padding: "10px" }}>

                                    <Grid container direction='row' >
                                        <Grid item xs={6} >
                                            <Typography variant="body2" color="text.secondary">
                                                ORDER PLACED  <br></br>
                                                {fDateTimeSuffix(order?.date)}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            <Typography variant="body2" color="text.secondary">
                                                Total <br></br>
                                                <b> ₹</b>  {order?.totalAmount}
                                            </Typography>
                                        </Grid>


                                    </Grid>

                                </CardActions>
                                <Divider />
                                <CardContent>
                                    {
                                        order &&
                                        order?.items?.map((item, i) => {
                                            return <Grid key={i} container direction='row' >
                                                <Grid item xs={11} direction='row' container >
                                                    <Grid item sx={{}}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: 50, height: 50, m: 1 }}
                                                            src={getProductImage(item.product_id)}
                                                            alt={item?.pro_info?.title}
                                                        />
                                                    </Grid>
                                                    <Grid sx={{}} item > <Box sx={{ m: 2 }}>  {item?.pro_info?.title?.toUpperCase()}</Box></Grid>
                                                </Grid>
                                                <Grid item xs={1} > <Box sx={{ m: 2 }}> Qnty {item.qnty}</Box></Grid>
                                            </Grid>
                                        })
                                    }
                                </CardContent>
                                <Divider />
                                <CardActions sx={{ padding: '10px' }}>
                                    <Grid container direction='row' >
                                        <Grid item xs={6} >
                                            <Typography variant="body2" color="text.secondary">
                                                ORDER  : #   {order?.orderNumber}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container direction='row' >
                                                <Grid item xs={12} sm={8}>
                                                    <Stack direction='row'>
                                                        <Button
                                                            onClick={() => handleChangeModal(order, order?.review)}
                                                            size="small" >
                                                            Rate & Review
                                                        </Button>
                                                        {/* <Rating
                                                            name="simple-controlled"
                                                            value={rating}
                                                            onChange={(event, newRating) => {
                                                                setRating(newRating);
                                                            }}
                                                        /> */}
                                                    </Stack>

                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ pl: 2 }}>
                                                    <Divider orientation="vertical" flexItem />
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        onClick={() => handleChangeHelpModal(order)}
                                                        size="small" >
                                                        Help
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                            {/* {Review({ questions, current, total })} */}
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        })
                    }

                </Box> :
                    <OrderInfo>
                        <BlockTitle>
                            <Box sx={{ p: 2 }}>
                                No previous order to show
                            </Box>
                        </BlockTitle>
                    </OrderInfo>
            }

            <Modal
                isOpen={showModal}
                closeModal={handleCloseModal}
                title={"Review Order"}
                dialogProps={{}}
                titleStyle={{ textAlign: "left", marginTop: 2 }}
                actionStyle={{ justifyContent: "right", marginBottom: 2 }}
                contentStyle={{ width: '100%', height: '500px' }}
                dialogStyle={{}}
                content={
                    <>

                        <Box sx={{ p: 2, }}>
                            <Typography variant="h6" component="div" gutterBottom>
                                Hope you had a good experience with us…anything we could improve or that you liked?
                            </Typography>
                            {/* {Review({ questions, current, total })} */}
                            <CommentAndRating onClick={callbackSetRating} default={{ comment: review?.comment, star: review?.star }} isValid={{valid}} />
                        </Box>
                    </>
                }
                action={
                    <>
                        {/* <Grid container direction='row'>
                            <Grid item>{(currentQuestion === 0) ? "" : <Button onClick={() => setCurrentQuestion((currentQuestion === 0) ? currentQuestion : currentQuestion - 1)}>Previous</Button>} </Grid>
                            <Grid item> {(currentQuestion + 1 === questions.length) ? "" : <Button onClick={() => setCurrentQuestion(((currentQuestion + 1) === questions.length) ? currentQuestion : currentQuestion + 1)}>Next</Button>}</Grid>
                        </Grid> */}
                        <Button
                            size="large"
                            variant="contained"
                            color="error"
                            onClick={handleCloseModal}>Close</Button>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitRating}>Submit</Button>
                    </>
                }
            />

            <Modal
                isOpen={showHelpModal}
                closeModal={handleCloseHelpModal}
                content={<HelpAndReport order={selectedOrder} />}
            />

        </>
    );
};

export default PreviousOrder;
