// OrderCustomizationPopup.js
import React from "react";
import { Stack, FormLabel, RadioGroup, FormControlLabel, Radio, Paper, Box, Fab, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckoutBtn from "../features/cart/CheckoutBtn"; // Adjust import as necessary

const OrderCustomizationPopup = ({
  isOpen,
  onClose,
  selectedItem,
  isDetailed,
  setIsDetailed,
  setDetailedRecipeArr,
  quantity,
  setQuantity,
  setSelectedRecipe,
  setRecipeFn,
  handleCreateRecipe,
  materialData,
  detailedRecipeArr,
  isValid,
  getMaterialName,
  isInCart,
  sendSelectedItemToCart,
  removeSelectedItemFromCart,
  getItemQuantity,
  classes,
  sendDetailedRecipe,
  selectedRecipe
}) => {
  if (!selectedItem || !isOpen) return null;

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10000,
      }}
    >
      <div className="row justify-content-center pt-3">
        <div className="cust_continer card">
          <div className="row ">
            <div className="col-md-3 d-flex align-items-center justify-content-center">
              <div className="customise_img_cont">
                <img
                  className="customise_img"
                  src={selectedItem?.images?.url}
                  alt={selectedItem?.title}
                />
              </div>
            </div>
            <div className="col-md-9 p-2">
              <div className="d-flex justify-content-between">
                <h4>Customize your {selectedItem?.title}</h4>
                {isDetailed ? (
                  <a onClick={() => { setIsDetailed(!isDetailed); setDetailedRecipeArr([]) }} style={{ fontSize: 12 }} className="mx-4 pointer">Back</a>
                ) : (
                  <span style={{ fontSize: 12 }} className="mx-4 text-secondary">Want to create own recipe? <a onClick={() => { setIsDetailed(!isDetailed); setSelectedRecipe("") }} className="pointer">click here</a></span>
                )}
              </div>
              <div className="cust_opt scrollableDiv pt-4">
                <div className="row align-items-center py-2">
                  <Stack direction="column" className="px-5" spacing={1} mb={1}>
                    <FormLabel id="demo-controlled-radio-buttons-group">Choose Quantity</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="role"
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                        setSelectedRecipe();
                      }}
                    >
                      {selectedItem?.customize[0]?.quantityArray?.map(
                        (i) => i.quantityName !== null && (
                          <FormControlLabel
                            className="text-capitalize"
                            value={i.quantityName}
                            control={<Radio />}
                            label={i.quantityName}
                          />
                        )
                      )}
                    </RadioGroup>
                  </Stack>
                </div>
                {!isDetailed ? (
                  <div className="row align-items-center py-2">
                    <Stack direction="column" className="px-5" spacing={1} mb={1}>
                      <FormLabel id="demo-controlled-radio-buttons-group">Choose Recipe</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="role"
                        onChange={(e) => { setRecipeFn(e.target.value); setDetailedRecipeArr([]) }}
                      >
                        {selectedItem?.customize[0]?.recipe?.map(
                          (i) => i.name !== null && (
                            <FormControlLabel
                              className="text-capitalize"
                              value={JSON.stringify(i)}
                              control={<Radio />}
                              label={i.name}
                            />
                          )
                        )}
                      </RadioGroup>
                    </Stack>
                  </div>
                ) : (
                  <>
                    {selectedItem?.customize[0]?.material?.length > 0 && (
                      <div className="row align-items-center p-3">
                        {isValid && <span style={{ color: "red", fontSize: 14 }}>Please select all ingredients *</span>}
                        <Paper sx={{ width: "100%" }} variant="outlined">
                          <Stack direction="column" spacing={1} mb={1}>
                            <FormLabel className="my-2">Detailed Customize</FormLabel>
                            <div className="row">
                              {selectedItem?.customize[0]?.material?.filter((j) => j.quantity === quantity)?.map(
                                (i) => i.name !== null && (
                                  <div className="col-md-4 py-2">
                                    <div className="row ">
                                      <div className="col-5 text-end py-2">
                                        <span className="fw-semibold">{getMaterialName(materialData, i.name)}</span>
                                      </div>
                                      <div className="col-7">
                                        <select
                                          onChange={(e) => {
                                            setSelectedRecipe("");
                                            handleCreateRecipe(e.target.value)
                                          }}
                                          value={detailedRecipeArr[i.name]}
                                          className="form-select"
                                        >
                                          <option>--Select--</option>
                                          {i.options.map((j) => (
                                            <option value={j.code}>{j.name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </Stack>
                        </Paper>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="row d-flex justify-content-between">
                <Box className="col-md-6 pt-3">
                  <CheckoutBtn onClick={onClose} />
                </Box>
                <div className="col-md-6">
                  <div className="d-flex float-end pt-4 px-2">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <button
                        onClick={onClose}
                        className="btn btn-secondary btn-sm mb-2"
                      >
                        <b>Cancel</b>
                      </button>
                    </Box>
                    {isInCart && !isInCart(selectedItem._id) ? (
                      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                        <Fab
                          onClick={() => {
                            if (detailedRecipeArr.length > 0) {
                              sendSelectedItemToCart(
                                selectedItem,
                                "",
                                "detailedCustomize",
                                sendDetailedRecipe(selectedItem?.customize[0]?.material?.filter((j) => j.quantity === quantity)?.length)
                              );
                            } else {
                              sendSelectedItemToCart(
                                selectedItem,
                                "",
                                "customize",
                                selectedRecipe
                              );
                            }
                          }}
                          variant="extended"
                          size="small"
                          className="btn btn-success"
                          aria-label="add"
                        >
                          <b>Add to Cart</b>
                        </Fab>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                        <Fab
                          variant="extended"
                          size="small"
                          className={classes.root}
                          sx={{ backgroundColor: "black", color: "white" }}
                          aria-label="addDelete"
                        >
                          <RemoveCircleOutlineIcon
                            onClick={() => removeSelectedItemFromCart(
                              selectedItem,
                              "",
                              "customize",
                              selectedRecipe || ""
                            )}
                          />
                          <Typography sx={{ padding: "10px" }} variant="subtitle1" color="white" component="div">
                            {getItemQuantity(selectedItem._id)}
                          </Typography>
                          <AddCircleOutlineIcon
                            onClick={() => {
                              if (detailedRecipeArr.length > 0) {
                                sendSelectedItemToCart(
                                  selectedItem,
                                  "",
                                  "detailedCustomize",
                                  sendDetailedRecipe(selectedItem?.customize[0]?.material?.filter((j) => j.quantity === quantity)?.length)
                                );
                              } else {
                                sendSelectedItemToCart(
                                  selectedItem,
                                  "",
                                  "customize",
                                  selectedRecipe
                                );
                              }
                            }}
                          />
                        </Fab>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCustomizationPopup;
