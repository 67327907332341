// src/components/CustomOptions.js

import React from 'react';

const CustomOptions = ({ options, triggerNextStep }) => {
  const handleClick = (option) => {
    triggerNextStep({ value: option.value, trigger: option.trigger });
  };

  return (
    <div style={{ display: 'flex', flexDirection:'column',width:'100%',background:"none" }}>
      {options.map(option => (
        <button
          key={option.value}
          onClick={() => handleClick(option)}
          style={{
            padding: '10px 20px',
            margin: '5px',
            backgroundColor: '#EBF8F2',
            color: '#00AB55',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize:14
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default CustomOptions;
