// material
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  TextField,
} from "@mui/material";
// components
import { MotionContainer } from "../../components/animate";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import useModal from "../../hooks/useModal";
import OTPModal from "../../components/Modal/OtpModal";
import Modal from "../../components/Modal/Modal";
import { useState } from "react";
import { postMethod } from "../../utils/api";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { API_URL } from "../../constants/url.constant";
import { API_GATEWAY } from "../../constants/url.constant";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function ForgotPassword() {
  const { isModalOpen, showModal, hideModal } = useModal();
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const handleResetClick = async () => {
    try {
      // setLoading(true);
      const client_url = window.location.origin
      const response = await postMethod(API_URL.FORGOT_PASSWORD, { email, client_url }, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        // console.log(response)
        setMsg(response?.data.message);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  }


  const handleChange = (e) => {
    setEmail(e.target.value);
  };


  return (
    <>
      <Modal
        isOpen={isModalOpen}
        closeModal={hideModal}
        content={<OTPModal />}
      />
      <RootStyle title="forgot password | Clover Carte">
        <Container>
          <MotionContainer initial="initial" open>
            <Box
              sx={{ maxWidth: 480, margin: "auto", mt: 30, textAlign: "left" }}
            >
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>

              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                Please enter the email address associated with your account and
                We will email you a link to reset your password.
              </Typography>

              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >

                  <TextField
                    fullWidth
                    value={email}
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    placeholder="Please enter your email"
                    onChange={handleChange}
                  />
                </Stack>
              </Box>
              <Box>
                {msg}

              </Box>

              <Button
                to="/"
                size="large"
                sx={{ mt: 3 }}
                variant="contained"
                fullWidth={true}
                onClick={handleResetClick}
              >
                Reset Password
              </Button>

              <Button to="/login" size="large" sx={{ mt: 2 }} fullWidth={true}>
                Back
              </Button>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </>
  );
}
