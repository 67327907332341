import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { decodeMachineMsg } from "../../utils/decodeOrderstatus";
import { API_URL, WEBSOCKET_GATEWAY } from "../../constants/url.constant";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { flushSync } from "react-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import Modal from "../../components/Modal/Modal";

import { Stack, Divider } from "@mui/material";
import toastr from "toastr";

import {
  OrderInfo,
  BlockTitle,
} from "../../features/order/order/order-received.style";
import { DotLoader } from "../Loader/DotLoader/DotLoader";
import LinearWithPercentage from "../Loader/ProgressBar/LinearWithPercentage";
import CommentAndRating from "../Review/CommentAndRating";
import { getMethod, postMethod, putMethod } from "../../utils/api";
import { API_STATUS_CODE, MESSAGE } from "../../constants/content.constant";
import { getArticleName } from "../../utils/helper";
import useOrderStatus from "../../utils/CheckOrderStatus";

const client = new W3CWebSocket(WEBSOCKET_GATEWAY);

client.onopen = () => {
  console.log("WebSocket Client Connected");
};

// Listen for messages from the WebSocket server
client.onmessage = (message) => {
  console.log("Message from server:", message.data);
};

// Optional: Handle connection errors
client.onerror = (error) => {
  console.error("WebSocket Error:", error);
};

// Optional: Handle connection close
client.onclose = () => {
  console.log("WebSocket Client Disconnected");
};

export default function OrderStatus(props) {
  const [review, setReview] = useState({ comment: null, star: 0 });
  console.log(props.data)
  useEffect(()=>{setOrderStatus(props.data ? props.data :[])},[])
  const [orderStatus, setOrderStatus] = useState([]);
  const {message, isLoading,token,showRateing,bar} = useOrderStatus(orderStatus[0])

  useEffect(() => {
    if (token) {
      // Create a deep copy of orderStatus to avoid mutating state directly

      // Iterate over orderStatus items to find the matching snum in food_status
      orderStatus.forEach(order => {
        order.items.forEach(item => {
          item.food_status.forEach(foodStatus => {
            if (foodStatus.snum === token) {
              // Update the food status with the data from useOrderStatus
              // foodStatus.status = message.status; // Assuming message contains the new status
              foodStatus.message = message; // Add message field to foodStatus
              foodStatus.showRateing = showRateing; // Add message field to foodStatus
            } else {
              // Preserve existing message if token doesn't match
              if (!foodStatus.message) {
                foodStatus.message = ''; // Initialize if message doesn't exist
              }
            }
          });
        });
      });

    }
  }, [message, token]);
  
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [value, setValue] = useState(0);

  const [statusText, setStatusText] = useState("")
  useEffect(()=>{fetchArticle()},[])
  const [productData,setProductData] = useState([]);
  


  useEffect(() => {
    if (props.data) {
      setOrderStatus([props.data]);
    }
  }, [props.data]);

  const handleCloseModal = () => setShowModal(false);

  const callbackSetRating = (reviewData, type) => {
    setReview((prevReview) => ({
      ...prevReview,
      [type]: reviewData,
    }));
  };
const [valid,setValid] = useState(false)
  const handleSubmitRating = async () => {
    review.order_id = selectedOrder?._id;
      const data = review;
   if(data.star){ try {
      
      let response;
      if (review?.id) {
        response = await putMethod(
          `${API_URL.UPDATE_REVIEW}/${review?.id}`,
          data,
          false,
          false
        );
      } else {
        response = await postMethod(API_URL.POST_REVIEW, data, false, false);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        toastr.success(MESSAGE.REVIEW_POST_SUCCESS);
        handleCloseModal();
      }
    } catch (error) {
      console.log(error);
    }}else{
      setValid(true)
    }
  
  };
  const showBar = (status) => {
    if (status === "OK" || status === "st" || status === "pr"|| status === "kk") {
      return <LinearProgress />;
    }
  };

  const showIcon = (status) => {
    if (["FL", "ut", "to", "tt", "fl"].includes(status)) {
      return (
        <ReportGmailerrorredIcon sx={{ color: "red", fontSize: "20px" }} />
      );
    }
    if (status === "kk"|| status === "rd") {
      return <VerifiedIcon sx={{ color: "green", fontSize: "20px" }} />;
    }
  };

  const handleChangeModal = (order_id, defaultReview) => {
    setReview({
      ...review,
      ["comment"]: defaultReview?.comment,
      ["star"]: defaultReview?.star,
      ["id"]: defaultReview?._id,
    });
    setShowModal(true);
    setSelectedOrder(order_id);
  };

  const fetchArticle = async () => {
    try {
      const response = await getMethod(API_URL.GET_ARTICLE, false, false);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setProductData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findProductIdByToken = (orderData, token) => {
    let productId = null;
    
    orderData.items.forEach(item => {
      item.food_status.forEach(status => {
        if (status.snum === token) {
          productId = item.product_id;
        }
      });
    });
    
    return productId;
  };

  return (
    <div>
      {!isLoading ? (
        orderStatus.map((order) => (
          <Card key={order?.orderNumber} spacing={2} sx={{ marginTop: "10px" }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Order Number: {order.orderNumber} <br />
                <Box sx={{ width: "100%" }}>{showBar(order.status)}</Box>
              </Typography>
              {order.items.map((item) => (
                item.food_status.slice(0, item.qnty).map((status, index) => (
                  <Card key={`${item._id}-${index}`} sx={{ marginBottom: "10px" }}>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Order Item: {getArticleName(productData, item.product_id)} {status.message&&showIcon("kk")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        Token Number: {status.snum} <br />
                        {token==status.snum&&
                        <Box sx={{ width: "100%" }}>{showBar(bar)}</Box>}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {status.message||'Order In Waiting...'}
                      </Typography>
                    </CardContent>
                    <Divider />
                    {status.showRateing && (
                      <CardActions sx={{ justifyContent: "space-around" }}>
                        <Stack direction="row">
                          <Button
                            onClick={() => handleChangeModal(order, order?.review)}
                            size="small"
                          >
                            Rate & Review
                          </Button>
                        </Stack>
                        <Divider orientation="vertical" flexItem />
                        <Stack direction="row">
                          <Button size="small">Help</Button>
                        </Stack>
                      </CardActions>
                    )}
                  </Card>
                ))
              ))}
            </CardContent>
          </Card>
        ))
        
        
      ) : (
        <OrderInfo>
          <BlockTitle>
            <Box sx={{ p: 2 }}>
              <LinearWithPercentage />
            </Box>
          </BlockTitle>
        </OrderInfo>
      )}
      <Modal
        isOpen={showModal}
        closeModal={handleCloseModal}
        title={"Review Order"}
        dialogProps={{}}
        titleStyle={{ textAlign: "left", marginTop: 2 }}
        actionStyle={{ justifyContent: "right", marginBottom: 2 }}
        contentStyle={{ width: "100%", height: "400px" }}
        dialogStyle={{}}
        content={
          <>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" component="div" >
                Hope you had a good experience with us…anything we could improve
                or that you liked?
              </Typography>
              {/* {Review({ questions, current, total })} */}
              <CommentAndRating
                onClick={callbackSetRating}
                default={{ comment: review?.comment, star: review?.star }}
                isValid={{valid}}
              />
            </Box>
          </>
        }
        action={
          <>
            {/* <Grid container direction='row'>
                            <Grid item>{(currentQuestion === 0) ? "" : <Button onClick={() => setCurrentQuestion((currentQuestion === 0) ? currentQuestion : currentQuestion - 1)}>Previous</Button>} </Grid>
                            <Grid item> {(currentQuestion + 1 === questions.length) ? "" : <Button onClick={() => setCurrentQuestion(((currentQuestion + 1) === questions.length) ? currentQuestion : currentQuestion + 1)}>Next</Button>}</Grid>
                        </Grid> */}
            <Button
              size="large"
              variant="contained"
              color="error"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleSubmitRating}
            >
              Submit
            </Button>
          </>
        }
      />
    </div>
  );
}
