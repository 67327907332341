import { useEffect, useState } from "react";
// library
import {
    Grid,
    Container,
    Card,
    FormLabel,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Button,
    Stack,
    Paper,
    InputBase,
    Typography,
    IconButton

} from "@mui/material";

import { styled } from "@mui/material/styles";

// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
// utils
import { getMethod, postMethod } from "../utils/api";
// constants
import { API_URL } from "../constants/url.constant";
import { API_STATUS_CODE, OPTIONS_ROLES } from "../constants/content.constant";
import OrderReceivedWrapper, { OrderReceivedContainer } from "../features/order/order/order-received.style";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ROUTES_URL } from "../constants/url.constant";
import { getUser } from "../utils/getUser.js";
import useModal from "../hooks/useModal.js";
import Modal from "../components/Modal/Modal.js";
import { PaymentMethod } from "../components/paymentAction/PaymentAction.js";
import { PayOrderByWalletAction } from "../components/paymentAction/PayOrderByWalletAction.js";
import { useCart } from "../contexts/cart/use-cart.jsx";
import { useNavigate } from 'react-router-dom';
import IconGooglePay from '../assets/images/google-pay-icon.png'
import IconMasterCard from '../assets/images/master-card-icon.png'
import IconRupay from '../assets/images/rupay-logo-icon.png'
import IconPohnePe from '../assets/images/phonepe-logo-icon.png'
import IconUpi from '../assets/images/upi-icon.png'
import IconCoupon from '../assets/images/coupon2.png'
import toastr from "toastr";


// ----------------------------------------------------------------------
import { WalletIcon } from '@mui/icons-material/Wallet';
import RechargeWallet from "../features/wallet/RechargeWallet.js";
import { useSelector } from "react-redux";
import useMachineState from "../utils/CheckMachineState.js";
import MachineStausPopup from "../components/Machine/MachineStausPopup.js";
import { getLocalData } from "../utils/helper.js";

export default function PaymentOptions(props) {
    const navigate = useNavigate();
    const data = useSelector(state => state.machineSlice.machine);
    const {statusOK,statusMessages,string} = useMachineState(true)
    const [walletData, setWalletData] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [isLoading, setLoading] = useState(null);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [message, setMessage] = useState(null);
    const user = JSON.parse(getUser())
    const { isModalOpen, showModal, hideModal } = useModal();
    const { cartItemsCount, calculatePrice, items } = useCart();
    const amountToBePaid = calculatePrice() - discount
    let payDetails = {
        total_amount: calculatePrice() - discount,
        title: '',
        items: items,
        machine:data
    };

    const BpIcon = styled("span")(({ theme }) => ({
        borderRadius: "50%",
        width: 24,
        height: 24,
        boxShadow:
            theme.palette.mode === "dark"
                ? "0 0 0 1px rgb(16 22 26 / 40%)"
                : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        ".Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background:
                theme.palette.mode === "dark"
                    ? "rgba(57,75,89,.5)"
                    : "rgba(206,217,224,.5)"
        }
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 24,
            height: 24,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    });

    function BpRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    const handleChange = (event) => {
        setSelectedMethod(event.target.value);
    };

    const RenderPaymentIcon = () => {
        return <>
            <Stack direction="row" sx={{ p: 1 }} spacing={1}>
                <img height="25px" src={IconGooglePay}></img>
                <img height="30px" src={IconMasterCard}></img>
                <img height="30px" src={IconRupay}></img>
                <img height="30px" src={IconUpi}></img>
                <img height="30px" src={IconPohnePe}></img>

            </Stack>
        </>
    };

    const CouponCodeInputAndVerify = () => {
        return (<Stack sx={{ mt: 2, pt: 2 }} direction="column">
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ color: "text.secondary" }}>
                    {message}
                </Typography>
                {
                    appliedCoupon ?
                        <Typography sx={{ color: "text.secondary" }}>
                            Total Discount :₹  {discount}
                        </Typography>
                        :
                        ""
                }
            </Box>

            <Paper
                component="form"
                sx={{ p: "2px 4px", display: "flex", alignItems: "center", border: "1px solid green" }}
            >
                <IconButton sx={{ p: '5px' }} aria-label="menu">
                    <img height="35px" src={IconCoupon}></img>
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Type Coupon Code"
                    onChange={handleChangeCoupon}
                    value={selectedCoupon}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth

                />
                <Button
                    onClick={() => handleApplyClick()}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    disabled={selectedCoupon == null ? true : false}
                >
                    Apply
                </Button>
            </Paper>
        </Stack>
        );
    }

    const handleApplyClick = async () => {
        if (!selectedCoupon) {
            setMessage("Please enter a valid Coupon Code")
        }
        try {
            setLoading(true);
            const response = await postMethod(API_URL.VERIFY_COUPON, { coupon: selectedCoupon }, false, true);
            if (response?.status === API_STATUS_CODE.SUCCESS &&
                response?.data?.data?.type !== "WELCOME") {
                setMessage("Coupon Code " + selectedCoupon.toUpperCase() + " applied succesfully");
                setAppliedCoupon(response?.data?.data);


            } else {
                setMessage("Coupon Code is invalid or expired")
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleChangeCoupon = (event) => {
        setMessage(null)
        setDiscount(0)
        setAppliedCoupon(null)
        setSelectedCoupon(event.target.value);
    };

    function handleProceedClick() {
        console.log(statusOK)
        if(statusOK===true){
        if (selectedMethod === "wallet") {
            PayOrderByWalletAction(payDetails)
        } else {
            PaymentMethod(payDetails)
        }}else{
            toastr.warning("Machine is busy right now, please try after some time.")
        }
    }


    function handleCancelClick() {
        const CURRENT_HOME = getLocalData("HOME");
        navigate(CURRENT_HOME ? ("/sp/" + CURRENT_HOME).toString() : "/");
    }

    const fetchWallet = async () => {
        try {
            // setLoading(true);
            const response = await getMethod(API_URL.GET_WALLET + '/' + user?.email, false, false);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setWalletData(response?.data?.data);
            }
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    const applyCouponCode = () => {
        let discountAmount = 0
        if (appliedCoupon && appliedCoupon?.type === "PERCENTAGE") {
            discountAmount = calculatePrice() * appliedCoupon.value / 100
            discountAmount = Math.round((discountAmount + Number.EPSILON) * 100) / 100
        }
        if (appliedCoupon && appliedCoupon?.type === "FIXED") {
            discountAmount = appliedCoupon.value
        }
        if (discountAmount > calculatePrice()) {
            setMessage("Selected Coupon Code is not applicable on this order ")
        } else {
            setDiscount(discountAmount)

        }
    };
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMethod]);

    useEffect(() => {
        applyCouponCode()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedCoupon]);

    useEffect(() => {
        try {
            fetchWallet();
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const machineState1 = useMachineState()
    const [alert,setAlert] = useState(true)
    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeModal={hideModal}
                content={<RechargeWallet />}
            />
            <Outlet />
            <Page title="Clover Carte | Payment Options ">
                <OrderReceivedWrapper>
     <MachineStausPopup data={true} />
                    <OrderReceivedContainer>
                        <Link to={ROUTES_URL.HOME}>
                            <p className="home-btn">
                                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
                            </p>
                        </Link>

                        <Container maxWidth="xl" sx={{ mt: 5 }}>
                            <Grid container spacing={3}>
                                <FormControl>
                                    <FormLabel id="demo-customized-radios">
                                        Amount to be Paid Rs. - {amountToBePaid}
                                        <br></br>
                                        Select payment method
                                    </FormLabel>
                                    <RadioGroup
                                        // defaultValue="wallet"
                                        aria-labelledby="demo-customized-radios"
                                        name="customized-radios"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="wallet"
                                            disabled={(walletData?.balance < amountToBePaid) ? true : false}
                                            control={<BpRadio />}
                                            label={"Wallet Balance -  Rs." + walletData?.balance}
                                        />
                                        {(walletData?.balance < amountToBePaid) ?
                                            <Card sx={{ p: 1, m: 1, backgroundColor: 'orange' }}>
                                                You do not have sufficient Wallet balance
                                            </Card> :
                                            ""
                                        }

                                        <FormControlLabel
                                            value="online"
                                            control={<BpRadio />}
                                            label="UPI, Cards and QR etc"
                                        />
                                        <Box sx={{ ml: 4 }}>

                                            {RenderPaymentIcon()}


                                        </Box>
                                        <Box>
                                            {CouponCodeInputAndVerify()}
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                                <Grid container direction="row" spacing={3} sx={{ mt: 5 }}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Button onClick={() => handleCancelClick()} fullWidth size="large" color="warning" variant="contained">
                                            {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */} Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Button onClick={() => handleProceedClick()} fullWidth size="large" color="primary" variant="contained">
                                            {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */}Proceed
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Container>
                    </OrderReceivedContainer>
                </OrderReceivedWrapper>

            </Page>
        </>
    );
}
