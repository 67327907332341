import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { API_URL } from "../../constants/url.constant";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { getMethod } from "../../utils/api";


export default function CategoryScrollbar(props) {
    const [value, setValue] = useState("All");
    const [categoryData, setCategoryData] = useState([]);
    const point_id = props.spid
    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.onClick(newValue)
    };

    const fetchCategory = async () => {
        const categoryResponse = await getMethod(API_URL.GET_CATEGORY_BY_POINT + "/" + point_id, false, false);
        if (categoryResponse?.status === API_STATUS_CODE.SUCCESS) {
            setCategoryData(categoryResponse?.data?.data);

        }
    };

    useEffect(() => {
        try {
            fetchCategory();

        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab value='All' label='All' />
                {categoryData &&
                    categoryData?.length > 0 &&
                    categoryData.map((ct, index) => {
                        return <Tab key={index} value={ct._id} label={ct.name} />
                    })}
            </Tabs>
        </Box>
    );
}