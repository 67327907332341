export function setLocalData(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // console.log("error", error)
  }
}

export function removeLocalData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // console.log("error", error)
  }
}
export function getLocalData(key) {
  try {
    let data = localStorage.getItem(key);
    return data
  } catch (error) {
    // console.log("error", error)
  }
}

export function getSessionData(key) {
  try {
    let data = sessionStorage.getItem(key);
    return data
  } catch (error) {
    // console.log("error", error)
  }
}
export function getMachineCode(data,key) {
  try {
    let code = data?.filter((i)=>i._id===key)[0]?.machine_code
    return code
  } catch (error) {
    // console.log("error", error)
  }
}

export function getMaterialName(data,key) {
  try {
    const material = data.find((item) => item._id === key);

    // Return the code of the material, if found
    return material ? material.name : null;
   
  } catch (error) {
    // console.log("error", error)
  }
}
export function getMaterialCode(data,key) {
  try {
    const material = data.find((item) => item._id === key);

    // Return the code of the material, if found
    return material ? material.code : null;
   
  } catch (error) {
    // console.log("error", error)
  }
}
export  function totalCustomizeCharges (selectedItem) {
  const data = selectedItem?.customize;
  const charge_data = data ? data[0]?.customizeCharges : [];
  const charge_price = charge_data?.map((i) => i.chargePrice);
  const total = charge_price.reduce((a, b) => +a + +b, 0);
  return total;
};
export  function totalCharges (selectedItem) {
  const data = selectedItem?.customize;
    const charge_data = data ? data[0]?.charges : [];
    const charge_price = charge_data.map((i) => i.chargePrice);
    const total = charge_price.reduce((a, b) => +a + +b, 0);
    return total;
};
export  function totalChargesInProduct (selectedItem) {
  const data = selectedItem
    const charge_data = data ? data?.charges : [];
    const charge_price = charge_data.map((i) => i.chargePrice);
    const total = charge_price.reduce((a, b) => +a + +b, 0);
    return total;
};
export  function slot_info (selectedItem) {
  const data = selectedItem?.machine;
  const slot_data = data ? data[0]?.slotsInfo : [];
  return [slot_data];
};
export  function container_info (selectedItem) {
  const data = selectedItem?.machine;
  const container_data = data ? data[0]?.container_info : [];
  return [container_data];
};
export  function showCustomizeButton (recipeData,id){
  const data = recipeData?.filter((i)=>i.article===id)[0]
  const assign = data?.recipe? data?.recipe?.length>0:false
  return assign
}
export function getArticleName(data,key) {
  try {
    let name = data?.filter((i)=>i._id===key)[0]?.title
    return name
  } catch (error) {
    // console.log("error", error)
  }
}



export  function calculatePrice  (recipe,slotData, containerData, supplierData)  {
  let totalPrice = 0;

  // Split the recipe string into individual ingredients
  const ingredients = recipe ? recipe.split("+") : null;

  // Loop through each ingredient
  if (ingredients) {
    ingredients.forEach((ingredient) => {
      // Extract the code and quantity from the ingredient string
      const code = ingredient.substring(0, 2);
      const quantity = ingredient.slice(-2);

      // Find the supplier corresponding to the ingredient in the containerData array
      const slot = slotData?.map((i) =>
        i?.filter(
          (material) =>
            material.name === code
        )
      )[0];
      const container = containerData.map((i) =>
        i.filter(
          (contianer) =>
          contianer.containerCode === slot[0]?.container_name
        )
      )[0];

      if (container) {
        // Find the supplier corresponding to the container
        const supplier = supplierData?.find(
          (supplierItem) => supplierItem._id === container[0]?.supplier
        );

        if (supplier) {
          // Find the ingredient in the supplier's supply_material
          const ingredientData = supplier?.supply_material?.find(
            (item) =>
              item.name ===
              container[0]?.material
          );
          if (ingredientData) {
            // Calculate the price for this ingredient
            totalPrice += ingredientData.perPrice * quantity;
            // totalPrice = quantity;
          }
        }
      }
    });
  }

  // return totalPrice;
  return Math.round(totalPrice);
};




export const checkMachineStatus = (status) => {
  // Ensure the status string is long enough
  // console.log(status);
  if (status.length < 14) {
    return false
  }

  // Extract values from specific positions
  const pos6 = status.charAt(6); // 7th position (index 6)
  const pos10 = status.charAt(10); // 11th position (index 10)
  const pos11 = status.charAt(11); // 12th position (index 11)
  const pos13 = status.charAt(13); // 14th position (index 13)

  // Determine the status message based on extracted values
  let statusMessage = 'Machine status: ';
  let isValid = true;

  if (pos6 === '0') {
    statusMessage += 'Machine is not connected to the server. ';
    isValid = false;
  } else {
    statusMessage += 'Machine is connected. ';
  }

  if (pos10 !== 'Z') {
    statusMessage += 'Machine is preparing an order. ';
    isValid = false;
  } else {
    statusMessage += 'Machine is not preparing an order. ';
  }

  if (pos11 === 'f') {
    statusMessage += 'Machine is free. ';
  } else if (pos11 === 'b') {
    statusMessage += 'Machine is busy. ';
    isValid = false;
  } else {
    statusMessage += 'Unknown status for position 11. ';
    isValid = false;
  }

  if (pos13 === 'W') {
    statusMessage += 'Machine is being cleaned. ';
    isValid = false;
  } else if (pos13 === 'w') {
    statusMessage += 'Machine cleaning process will end in 5 seconds. ';
    isValid = false;
  } else if (pos13 === '0') {
    statusMessage += 'Machine is not cleaning. ';
  } else {
    statusMessage += 'Unknown status for position 13. ';
    isValid = false;
  }

  // statusMessage += `Pos6: ${pos6}, Pos10: ${pos10}, Pos11: ${pos11}, Pos13: ${pos13}`;

  return isValid
};
