// material
import {

  Box,
  Button,
  Typography,
  Container,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { MotionContainer } from "../../components/animate";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import useModal from "../../hooks/useModal";
import OTPModal from "../../components/Modal/OtpModal";
import Modal from "../../components/Modal/Modal";
import { postMethod, getMethod } from "../../utils/api";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { API_URL } from "../../constants/url.constant";

import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';



const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export default function ResetPassword() {
  const { isModalOpen, showModal, hideModal } = useModal();
  const [showPassword, setShowPassword] = useState(false);
  const [linkActive, setLinkActive] = useState(true);
  const [msg, setMsg] = useState(null);
  const navigate = useNavigate();


  const { token, userId } = useParams()


  const [email, setEmail] = useState("");
  const handleResetClick = async () => {
    try {
      // setLoading(true);
      const response = await postMethod(API_URL.RESET_PASSWORD + "/" + token + "/" + userId, { email }, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        showModal();
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  }
  const verifyTokenExist = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.VERIFY_RESET_TOKEN + "/" + token + "/" + userId, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setLinkActive(true);
      } else {
        setLinkActive(false);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }

    // await fetch('user/verify/token?token=' + this.state.token + '&userId=' + this.state.userId)
    //   .then(res => res.json())
    //   .then(data => {
    //     this.setState({
    //       linkActive: data.linkActive
    //     })
    //   })
  }

  useEffect(() => {
    verifyTokenExist();
  })


  const LoginSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const { password, confirmPassword } = values;
      try {
        // setLoading(true);
        const response = await postMethod(API_URL.RESET_PASSWORD + "/" + token + "/" + userId, { password, confirmPassword }, false, true);
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setMsg(response?.data?.message);
          // console.log("success")
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
        // setLoading(false);
      }

    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  const logInOnClick = () => {
    navigate('/login');
  };



  const handleChange = (e) => {
    setEmail(e.target.value);
  };


  return (
    <>

      <RootStyle title="reset password | Clover Carte">
        <Container>
          <MotionContainer initial="initial" open>

            <Box
              sx={{ maxWidth: 480, margin: "auto", mt: 30, textAlign: "left" }}
            >
              {(!msg) ? (linkActive ? <Box>
                < Typography variant="h3" paragraph>
                  Reset your Password
                </Typography>

                <Box sx={{ margin: "20px 0" }}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack spacing={3}>
                        <TextField
                          fullWidth
                          autoComplete="current-password"
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          {...getFieldProps("password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />

                        <TextField
                          fullWidth
                          autoComplete="current-password"
                          type={showPassword ? "text" : "password"}
                          label="Confirm Password"
                          {...getFieldProps("confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Reset Password
                        </LoadingButton>

                        <Button to="/login" size="large" sx={{ mt: 2 }} fullWidth={true}>
                          Back
                        </Button>
                      </Stack>

                    </Form>
                  </FormikProvider>
                </Box>

                {/* <Button
                  to="/"
                  size="large"
                  sx={{ mt: 3 }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleResetClick}
                >
                  Reset Password
                </Button> */}

              </Box> : <Box>
                This Link is expired or inactive
              </Box>
              ) :
                <Box>
                  {msg}
                  <Box>

                    <Button
                      // to="/"
                      size="large"
                      sx={{ mt: 3 }}
                      variant="contained"
                      fullWidth={true}
                      onClick={logInOnClick}
                    >
                      Login Now
                    </Button>
                  </Box>
                </Box>
              }

            </Box>

          </MotionContainer>
        </Container>
      </RootStyle>
    </>
  );
}
