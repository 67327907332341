// VideoComponent.js
import React from "react";

const VideoComponent = () => {
  return (
    <div>
      <iframe
        width="315"
        height="440"
        src="https://www.youtube.com/embed/uJOZJgiu9bU"
        title="Clover Carte"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VideoComponent;
