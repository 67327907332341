import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { API_STATUS_CODE } from "../constants/content.constant";
import { API_URL } from "../constants/url.constant";
import { getMethod } from "./api";

const useMachineState = (shouldFetchRealTime) => {
  const data = useSelector((state) => state.machineSlice.machine);
  const codeno = data ? data.codeno : "";
  const [statusMessages, setStatusMessages] = useState([]);
  const [string, setString] = useState("");
  const [pos0, setPos0] = useState(null);
  const [pos10, setPos10] = useState(null);
  const [pos11, setPos11] = useState(null);
  const [pos6, setPos6] = useState(null);
  const [pos13, setPos13] = useState(null);
  const [statusOK, setStatusOK] = useState(true);
  const [reason, setReason] = useState("");

  const parseStatusString = useCallback((statusString) => {
    if (statusString.length < 14) {
      return {
        messages: ["Invalid status string"],
        pos6: null,
        pos13: null,
        statusOK: false,
        reason: "Status string is too short.",
      };
    }

    const pos0 = statusString.charAt(2);
    const pos6 = statusString.charAt(8);
    const pos10 = statusString.charAt(12);
    const pos11 = statusString.charAt(13);
    const pos13 = statusString.charAt(15);
    const messages = [];
    let statusOK = true;
    let reason = "";

    if (pos6 === "0") {
      messages.push("Machine is not connected to the server.");
      statusOK = false;
      reason = "Machine is not connected to the server.";
    } else {
      messages.push("Machine is connected.");
    }

    if (pos10 !== "Z") {
      messages.push("Machine is preparing an order.");
      statusOK = false;
    } else {
      messages.push("Machine is not preparing an order.");
    }

    if (pos11 === "f") {
      messages.push("Machine is free.");
    } else if (pos11 === "b") {
      messages.push("Machine is busy.");
      reason = "Machine is busy now";
      statusOK = false;
    } else {
      messages.push("Unknown status for position 11.");
      statusOK = false;
      reason = "Unknown status for position 11.";
    }

    if (pos13 === "W") {
      messages.push("Machine is being cleaned.");
      reason = "Machine is being cleaned.";
      statusOK = false;
    } else if (pos13 === "w") {
      messages.push("Machine cleaning process will end in 5 seconds.");
      statusOK = false;
      reason = "Machine cleaning process will end in 5 seconds.";
    } else if (pos13 === "0") {
      messages.push("Machine is not cleaning.");
    } else {
      messages.push("Unknown status for position 13.");
      statusOK = false;
      reason = "Unknown status for position 13.";
    }
    if (pos0 === "0") {
      messages.push(
        `Mcstat 0 :${pos0} Below cup dispenser Point (Starting Position)`
      );
    }
    if (pos0 === "9") {
      messages.push(`Mcstat 0 :${pos0} On delivery window () (End Position)`);
    }
    if (pos0 === "1") {
      messages.push(`Mcstat 0 :${pos0} Cold beverage point`);
    }
    if (pos0 === "2") {
      messages.push(`Mcstat 0 :${pos0} Hot Beverage Point`);
    }

    return {
      messages,
      statusOK,
      reason,
      pos0,
      pos6,
      pos10,
      pos11,
      pos13
    };
  }, []);

  useEffect(() => {
    const getMachineState = async () => {
      try {
        const response = await getMethod(
          API_URL.GET_MACHINE_STATE + "/" + codeno
        );
        if (response?.status === API_STATUS_CODE.SUCCESS) {
        //   const statusString = "0~0606611110Zb00U@100";
          const statusString = response.data;
          setString(response.data);
          const {messages,
            statusOK,
            reason,
            pos0,
            pos6,
            pos10,
            pos11,
            pos13 } =
            parseStatusString(statusString);
          setStatusMessages(messages);
          setPos6(pos6);
          setPos0(pos0);
          setPos10(pos10);
          setPos11(pos11);
          setPos13(pos13);
          setStatusOK(statusOK);
          setReason(reason);
          console.log(statusString);
        } else {
          console.error("Failed to fetch machine state:", response);
        }
      } catch (error) {
        console.error("Error fetching machine state:", error);
      }
    };

    if (codeno) {
      getMachineState();
      let intervalId;
      if (shouldFetchRealTime) {
        intervalId = setInterval(getMachineState, 5000);
      }
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [codeno, shouldFetchRealTime, parseStatusString]);

  return {statusMessages,
    statusOK,
    reason,
    pos0,
    pos6,
    pos10,
    pos11,
    pos13};
};

export default useMachineState;
