import React, { useEffect, useState } from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import OrderReceivedWrapper, {
  OrderReceivedContainer,
  OrderInfo,
  OrderDetails,
  TotalAmount,
  BlockTitle,
  Text,
  InfoBlockWrapper,
  InfoBlock,
  ListItem,
  ListTitle,
  ListDes,
} from "./order-received.style";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../../contexts/cart/use-cart";
import Page from "../../../components/Page";
import { Outlet } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { getMethod } from "../../../utils/api";
import { API_URL } from "../../../constants/url.constant";
import { API_STATUS_CODE } from "../../../constants/content.constant";
import OrderStatus from "../../../components/Order/OrderStatus";

import { ROUTES_URL } from "../../../constants/url.constant";
import useMachineState from "../../../utils/CheckMachineState";
import MachineStausPopup from "../../../components/Machine/MachineStausPopup";

const FullOrderStatus = (props) => {
  const { items, calculatePrice, calculateSubTotalPrice, clearCart } = useCart();
  const { id } = useParams()
  const [order, setOrder] = useState(null)
const {statusMessages,string}= useMachineState()
  const fetchInvoice = async () => {
    try {
      const response = await getMethod(API_URL.GET_ORDER_BY_ID + '/' + id, false, true);
      // console.log("error", response)
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setOrder(response?.data?.data)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    fetchInvoice()

  }, []);
  React.useEffect(() => {
    return () => {
      clearCart();
    };

  }, []);
  const [alert, setAlert] = useState(true)
  // yguiyguyg
  return (
    <>
      <Outlet />
      <Page title="Clover Carte | Order Status">
        <OrderReceivedWrapper>
       <MachineStausPopup data={true} />
          <OrderReceivedContainer>
            <Link to={ROUTES_URL.HOME}>
              <p className="home-btn">
                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
              </p>
            </Link>
            {order ? (
              <>
                <OrderInfo>
                  <BlockTitle>
                    <FormattedMessage
                      id="orderStatusText"
                      defaultMessage="Order Status"
                    />
                  </BlockTitle>

                  <Text>
                    <FormattedMessage
                      id="orderReceivedSuccess2"
                      defaultMessage="Thanks for ordering with Clover Carte."
                    />
                    <br></br>

                    <Link to={"/order-received/order-id/" + id}>
                      <a >
                        <FormattedMessage id="myOrderBtn" defaultMessage=" Download Invoice" />
                      </a>
                    </Link>

                  </Text>


                </OrderInfo>
                <OrderStatus data={order} />

              </>
            ) : (
              <OrderInfo>
                <BlockTitle>You don't have any orders.</BlockTitle>
              </OrderInfo>
            )}
          </OrderReceivedContainer>
        </OrderReceivedWrapper>
      </Page>
    </>

  );
};

export default FullOrderStatus;
