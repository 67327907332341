import React, { useEffect, useState } from 'react';
import { API_URL } from '../../constants/url.constant';
import { getMethod } from '../../utils/api';
import { API_STATUS_CODE } from '../../constants/content.constant';
import { getArticleName, getLocalData } from '../../utils/helper';

const FetchWalletComponent = ({ steps, triggerNextStep }) => {
  const localUser = getLocalData("user")
  const parserLocalUser = localUser?JSON.parse(localUser):{}

  
  const [waletInfo, setWaletInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [result, setResult] = useState(null); // Track result status

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await getMethod(API_URL.GET_WALLET + "/" + parserLocalUser.email, false, false);

        if (response?.status === API_STATUS_CODE.SUCCESS && response.data.data) {
          setWaletInfo(response.data.data);
          console.log(response.data.data);
          setResult("yes"); // Indicate that the order ID is valid
          triggerNextStep({ value: "yes" }); // Proceed to next step
        } else {
          console.error("Failed to fetch order details:", response);
          setResult("no"); // Indicate that the order ID is invalid
          triggerNextStep({ value: "no" }); // Re-prompt user for order ID
        }
      } catch (e) {
        console.error("Error fetching order details:", e);
        setResult("no"); // Treat fetch error as invalid order ID
        triggerNextStep({ value: "no" }); // Re-prompt user for order ID
      } finally {
        setLoading(false); // Set loading to false after fetch attempt
      }
    };

    fetchOrder();
  }, []);

  if (loading) {
    return <div>Loading your order details...</div>; // Display loading message
  }
// console.log(waletInfo)


  return (
    <div>
      {result === "yes" ? (
        <div>
          <h4>Wallet Details:</h4>
          <p>Available balance: {waletInfo.balance} </p>
          {/* <p>Items: {waletInfo.items.join(", ")}</p> */}
          {/* <p>Status: Delivered</p> */}
          {/* Add more order details as needed */}
        </div>
      ) : (
        <div>Sorry, we couldn't find an wallet details. Please try again.</div>
      )}
    </div>
  );
};

export default FetchWalletComponent;
