import * as React from "react";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, Link, Stack, Button } from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { postMethod } from "../../utils/api";
import { API_URL } from "../../constants/url.constant";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { signInWithOtp } from "../../store/slices/user.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';




export const useStyles = makeStyles({
  inputSize: {
    "& input": {
      width: '2.5em !important',
      height: '2.5em',
      margin: '8px 4px',
      background: '#f6f5fa',
      border: 'none',
      borderRadius: '8px'
    }
  }
})

export default function ResponsiveDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const mobileNumer = props.mobileNumer
  const classes = useStyles();
  const handleChange = (otp) => setOtp(otp);
  const confirmOTP = async () => {
    const payload = { mobile: mobileNumer, otp }
    dispatch(signInWithOtp(payload, navigate));
    // try {
    //   // setLoading(true);
    //   const response = await postMethod(API_URL.VERIFY_OTP, { mobile: mobileNumer, otp }, false, true);
    //   if (response?.status === API_STATUS_CODE.SUCCESS) {
    //     //navigate
    //   }
    //   // setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   // setLoading(false);
    // }
  };

  return (
    <DialogContentText align="center" p={5}>
      <Box
        component="img"
        sx={{
          height: 60,
          width: 60,
          margin: 'auto'
        }}
        alt="The house from the offer."
        src="https://cdn-icons-png.flaticon.com/512/1320/1320564.png"
      />
      <Box
        component="span"
        display="block"
        sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
      >
        Please Enter OTP
      </Box>

      {/* <Typography>
        {" "}
        Let Google help apps determine location. This means sending{" "}
      </Typography> */}
      <Box
        component="div"
        className={classes.inputSize}
        sx={{ display: "table", margin: 'auto' }}
      >
        <OtpInput
          value={otp}
          shouldAutoFocus={true}
          isInputNum={true}
          onChange={handleChange}
          numInputs={4}
          separator={<span> - </span>}
          align="center"
          sx={{ justifyContent: "center" }}
        />
      </Box>
      <Typography component="body1">
        Didn't recieve the OTP? <Link href="#">Resend</Link>{" "}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ m: 2 }}>
        <Button onClick={() => confirmOTP()} fullWidth size="large" color="inherit" variant="outlined">
          {/* <Icon icon={googleFill} color="#DF3E30" height={24} /> */} Verify and Proceed
        </Button>
      </Stack>
    </DialogContentText>
  );
}
