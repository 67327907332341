import { useEffect, useState } from "react";
// library
import {
    Grid,
    Container,
    Card,
    FormLabel,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Button,
    Stack,
    Paper,
    InputBase,
    Typography,
    IconButton

} from "@mui/material";

import { styled } from "@mui/material/styles";

// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
// utils
import { getMethod, postMethod } from "../utils/api.jsx";
// constants
import { API_URL } from "../constants/url.constant.jsx";
import { API_STATUS_CODE, OPTIONS_ROLES } from "../constants/content.constant.jsx";
import OrderReceivedWrapper, { OrderReceivedContainer } from "../features/order/order/order-received.style.jsx";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ROUTES_URL } from "../constants/url.constant.jsx";
import { getUser } from "../utils/getUser.js";
import useModal from "../hooks/useModal.js";
import Modal from "../components/Modal/Modal.js";
import { PaymentMethod } from "../components/paymentAction/PaymentAction.js";
import { PayOrderByWalletAction } from "../components/paymentAction/PayOrderByWalletAction.js";
import { useCart } from "../contexts/cart/use-cart.jsx";
import { useNavigate } from 'react-router-dom';
import ConfirmOrder from "../features/order/confirmOrder/ConfirmOrder.js";


// ----------------------------------------------------------------------
import { WalletIcon } from '@mui/icons-material/Wallet';
import RechargeWallet from "../features/wallet/RechargeWallet.js";

export default function ConfirmOrderPage(props) {
    const navigate = useNavigate();
    const [walletData, setWalletData] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [isLoading, setLoading] = useState(null);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [message, setMessage] = useState(null);
    const user = JSON.parse(getUser())
    const { isModalOpen, showModal, hideModal } = useModal();
    const { cartItemsCount, calculatePrice, items } = useCart();
    const amountToBePaid = calculatePrice() - discount
    let payDetails = {
        total_amount: calculatePrice() - discount,
        title: '',
        items: items
    };

    const BpIcon = styled("span")(({ theme }) => ({
        borderRadius: "50%",
        width: 24,
        height: 24,
        boxShadow:
            theme.palette.mode === "dark"
                ? "0 0 0 1px rgb(16 22 26 / 40%)"
                : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
        backgroundImage:
            theme.palette.mode === "dark"
                ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
                : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        ".Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background:
                theme.palette.mode === "dark"
                    ? "rgba(57,75,89,.5)"
                    : "rgba(206,217,224,.5)"
        }
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 24,
            height: 24,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    });


    const handleChange = (event) => {
        setSelectedMethod(event.target.value);
    };

    function handleProceedClick() {
        if (selectedMethod === "wallet") {
            PayOrderByWalletAction(payDetails)
        } else {
            PaymentMethod(payDetails)
        }
    }


    function handleCancelClick() {
        navigate(ROUTES_URL.HOME, { replace: true });
    }


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMethod]);


    return (
        <>

            <Outlet />
            <Page title="Clover Carte | Confirm Order ">
                <OrderReceivedWrapper>

                    <OrderReceivedContainer>
                        <Link to={ROUTES_URL.HOME}>
                            <p className="home-btn">
                                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
                            </p>
                        </Link>

                        <Container maxWidth="xl" sx={{ mt: 5 }}>
                            <Grid container spacing={3} direction="column">
                                <FormControl>
                                    <FormLabel id="demo-customized-radios">
                                        Please Confirm your order and check waiting time .
                                        <br></br>
                                    </FormLabel>

                                </FormControl>
                                <Grid>
                                    <ConfirmOrder />
                                </Grid>

                            </Grid>

                        </Container>
                    </OrderReceivedContainer>
                </OrderReceivedWrapper>

            </Page>
        </>
    );
}
