// src/components/CustomOptions.js

import React from 'react';

const CallingButton = ({ options, triggerNextStep }) => {
  const handleClick = (option) => {
    triggerNextStep({ value: option.value, trigger: option.trigger });
  };

  return (
    <div style={{ display: 'flex', flexDirection:'column',width:'100%',background:"none",justifyContent:'center' }}>
      {options.map(option => (
        <a href='tel:+91 8839153737' >
        <button
          key={option.value}
          onClick={()=>handleClick(option)}
          style={{
            padding: '10px 20px',
            margin: '5px',
            backgroundColor: 'green',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize:14,
            width:"80%",
            marginLeft:"10%"
          }}
        >
          {option.label}
        </button>
        </a>
      ))}
    </div>
  );
};

export default CallingButton;
